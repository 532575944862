import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import axios from '../../../../constents/axios';
import Select from 'react-select';
import { MdArrowBack, MdOutlineAddBox } from 'react-icons/md';

const AddSubTopics = ({ show, onHide, courseName, onAddSubTopicSuccess, currentTopicName }) => {
    const [subTopicName, setSubTopicName] = useState('');
    const [isCreatingNew, setIsCreatingNew] = useState(false);
    const [subTopics, setSubTopics] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchSubTopics = async () => {
        try {
            const response = await axios.post(`/get-subtopics`, {
                course_id: `${courseName}`,
                topic: currentTopicName
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`
                }
                });
            setSubTopics(response.data?.data || []); 
        } catch (error) {
            console.error('Error fetching subtopics:', error);
            // setErrorMessage('Failed to fetch subtopics. Please try again later.');
        }
    };

    const handleAddNewTopic = async () => {
      if (!courseName || !currentTopicName || !subTopicName.trim()) {
        setErrorMessage('All fields must be filled.');
        return;
      }
    
      try {
        const newSubTopic = subTopicName.trim();
        await axios.post(`/add-newSubTopic`, {
            course_id: courseName,
            topics: currentTopicName,
            subtopic: newSubTopic,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
            },
          }
        );

        fetchSubTopics();
        setIsCreatingNew(false);
        setMenuIsOpen(true);
        setSubTopicName('');
        onAddSubTopicSuccess();
      } catch (error) {
        console.error('Error adding new subtopic:', error.response?.data || error.message);
        setErrorMessage('Failed to add new subtopic. Please try again.');
      }
    };

    const handleAddSubTopic = async () => {
        if (!subTopicName.trim()) return;

        try {
            await axios.post(`/add-subtopic`, {
                id: 0,
                course_id: `${courseName}`,
                topics: `${currentTopicName}`,
                subtopic: `${subTopicName.trim()}`,
                material_type: "?",
                material_id: "?",
                priority: 1
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`
                }
                });
                
            const newSubTopic = {
                title: subTopicName.trim(),
                data: []
            };

            onAddSubTopicSuccess(newSubTopic);
            onHide();
        } catch (error) {
            console.error('Error adding subtopic:', error);
            if (error.response && error.response.status === 409) {
                setErrorMessage("Subtopic already exists. Please use a different name.");
              } else {
                // Handle other errors (e.g., network issues, server errors)
                setErrorMessage("An error occurred while adding the subtopic. Please try again.");
              }
              setTimeout(() => {
                setErrorMessage("");
              }, 3000);
          
        }
    };

    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value === 'create-new') {
            setIsCreatingNew(true);
        } else {
            setSubTopicName(selectedOption.value);
            setIsCreatingNew(false);
        }
    };

    const handleBackToSelect = () => {
        setIsCreatingNew(false);
        setSubTopicName('');
    };

    useEffect(() => {
        fetchSubTopics();
    }, [courseName, currentTopicName]);

    const options = [
        { value: 'create-new', label: <><MdOutlineAddBox /> Create New Topic</> },
        ...(subTopics?.filter((sub) => sub.subtopic && sub.subtopic.trim() !== "")
            .map((sub) => ({ value: sub.subtopic, label: sub.subtopic })) || []),
    ];

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add SubTopic</Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {isCreatingNew ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdArrowBack
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                size={24}
                                onClick={handleBackToSelect}
                            />
                            <Form.Control
                                size="lg"
                                type="text"
                                placeholder="Enter the SubTopic Name"
                                value={subTopicName}
                                onChange={(e) => setSubTopicName(e.target.value)}
                                required
                            />
                        </div>
                    ) : (
                        <Select
                            options={options}
                            onChange={handleSelectChange}
                            placeholder="Select a Topic"
                            onMenuOpen={() => setMenuIsOpen(true)}
                            menuIsOpen={menuIsOpen}
                            onMenuClose={() => setMenuIsOpen(false)}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={isCreatingNew ? handleAddNewTopic : handleAddSubTopic}
                        disabled={!subTopicName.trim()} // Disable if empty
                    >
                        {isCreatingNew ? 'Create' : 'Add SubTopic'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddSubTopics;
