import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from '../../../../constents/axios';

const DeleteMaterial = ({ show, onHide, topicName, subTopicName, courseName, materialId, materialType, onSuccess }) => {
    
    const handleDeleteMaterial = async () => {
        try {
            await axios.delete(`/delete-material`, {
                data: {
                    course_id: courseName,
                    topics: topicName,
                    subtopic: subTopicName || null,
                    material_id: materialId,
                    material_type: materialType
                }
            });
            onSuccess(); 
            onHide(); 
        } catch (error) {
            console.error('Error deleting topic and subtopic:', error);
        }
    };

    const handleDeleteFromMaterial = async () => {
        try {
            await axios.delete(`/delete-fromMaterial`, {
                data: {
                    course_id: courseName,
                    topics: topicName,
                    subtopic: subTopicName,
                    material_id: materialId,
                    material_type: materialType
                }
            },{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            onSuccess(); 
            onHide(); 
        } catch (error) {
            console.error('Error deleting topic and subtopic:', error);
        }
    };

    return (
        <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">Delete Material</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <span>Deleting this Material could wipe out everything else attached to it.</span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="danger" onClick={handleDeleteFromMaterial}>Delete From All Course</Button>
                <Button variant="danger" onClick={handleDeleteMaterial}>Delete From Student Journey</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteMaterial;
