import e from 'cors';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from '../../../constents/axios';

const CreateSection = ({show, handleClose, onSuccess}) => {
    const [questionSetId, setQuestionSetId] = useState('');
    const [title, setTitle] = useState('');
    const [duration, setDuration] = useState('');
    const [batchId, setBatchId] = useState('');
    const [positiveMark, setPositiveMark] = useState('');
    const [negativeMark, setNegativeMark] = useState('');
    const [questionAttendedLimit, setQuestionAttendedLimit] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            
            const formData = {
                questionSetId,
                title,
                duration,
                batchId,
                positiveMark,
                negativeMark,
                questionAttendedLimit
            };

            console.log(formData);
            const response = axios.post('/tests/createSection', formData, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa` // Replace with your actual token
                }
            });
            console.log("response",response);
            
            onSuccess();
            handleClose();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };



  return (
    <Modal show={show} onHide={handleClose} centered size='lg' >
      <Modal.Header closeButton>
        <Modal.Title>Create Section</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

        <div className='row'>
            <div className='col-md-6'>
                <Form.Group className="mb-3" >
                    <Form.Label>Question Set ID:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Question Set ID" onChange={(e) => setQuestionSetId(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Title:</Form.Label>
                    <Form.Control type="text" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Duration:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Duration for each Section" onChange={(e) => setDuration(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Batch ID:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Batch ID" onChange={(e) => setBatchId(e.target.value)} />
                </Form.Group>
            </div>
            <div className='col-md-6'>
                <Form.Group className="mb-3" >
                    <Form.Label>Positive Mark:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Positive Mark in a Section" onChange={(e) => setPositiveMark(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Negative Mark:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Negative Mark in a Section" onChange={(e) => setNegativeMark(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Label>Question Attended Limit:</Form.Label>
                    <Form.Control type="number" placeholder="Enter Question Attended Limit in Section" onChange={(e) => setQuestionAttendedLimit(e.target.value)} />
                </Form.Group>
            </div>
        </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" variant="outline-primary" onClick={handleSubmit} >
          <i className="fas fa-upload mr-2" aria-hidden="true"></i>
        </Button>
        <Button variant="outline-dark" onClick={handleClose}>
          <i className="fas fa-times mr-2" aria-hidden="true"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateSection