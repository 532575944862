import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from '../../../../constents/axios';
import styles from '../../../../styles/clone.module.css';
import Select from 'react-select';

const CloneCourse = ({ show, onHide, courseName, onSuccess }) => {
    const [labels, setLabels] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleCourseChange = (courseId) => {
        setSelectedCourse(courseId); // Single course selection
    };

    const handleLabelChange = (selectedOption) => {
        setSelectedLabel(selectedOption);
        setSelectedCourse(null); // Reset selected course when label changes
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (!selectedLabel || !selectedCourse) {
            setError('Please select a label and a course.');
            return;
        }
    
        const requestData = {
            course_id: courseName,
            cloneTo: selectedCourse,
        };
    
        // Log the request data to verify values before sending the request
        console.log('Sending request with data:', requestData);
    
        axios.post('/clone-course', requestData, {
            headers: {
                'Content-Type': 'application/json',
                'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
            }
        })
        .then(response => {
            // Handle successful response
            onSuccess();
            setShowSuccessMessage(true);
            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 3000);
        })
        .catch(error => {
            console.error('Error cloning topic:', error);
            setError(error.response ? error.response.data.error : error.message);
        });
    };
    

    useEffect(() => {
        const fetchLabels = async () => {
            try {
                const response = await axios.get('/fetch-label', {
                    headers: {
                        'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                    }
                });
                const labelOptions = response.data.data.map((label) => ({
                    value: label.label,
                    label: label.label,
                }));
                setLabels(labelOptions);
            } catch (error) {
                console.error("There was an error fetching labels:", error);
                setError(error.response ? error.response.data : error.message);
            } finally {
                setLoading(false);
            }
        };

        if (selectedLabel) {
            const fetchCourses = async () => {
                setLoading(true);
                try {
                    const response = await axios.post('/fetch-courseId-label', { label: selectedLabel.value },{
                        headers: {
                            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                        }
                    });
                    setCourses(response.data.result);
                } catch (error) {
                    console.error("There was an error fetching courses:", error);
                    setError(error.response ? error.response.data : error.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchCourses();
        }

        fetchLabels();
    }, [selectedLabel]);

    return (
        <Modal show={show} size="md" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Clone Topic</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    {loading && <Spinner animation="border" />}
                    {error && <Alert variant="danger">{error}</Alert>}
                    {showSuccessMessage && <Alert variant="success">Course cloned successfully!</Alert>}
                    {!loading && (
                        <>
                            <Select
                                placeholder="Select Label"
                                value={selectedLabel}
                                onChange={handleLabelChange}
                                options={labels}
                                isClearable
                            />
                            {courses?.length > 0 && (
                                <div>
                                    {courses?.map((course) => (
                                        <div key={course.course_id} className={styles.checkBox}>
                                            <Form.Check
                                                type="radio"
                                                label={course.title}
                                                name="course" 
                                                checked={selectedCourse === course.course_id}
                                                onChange={() => handleCourseChange(course.course_id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Close</Button>
                    <Button variant="primary" type="submit">Clone Submit</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CloneCourse;
