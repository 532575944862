import React, { useState, useEffect } from 'react';
import axios from '../../constents/axios';
import styles from '../../styles/page.module.css';
import Accordion from 'react-bootstrap/Accordion';
import { FaCirclePlus, FaFaceFrownOpen } from "react-icons/fa6";
import { MdDelete, MdEdit, MdOndemandVideo, MdAddBox } from "react-icons/md";
import { PiCopySimpleFill } from "react-icons/pi";
import { IoIosDocument } from "react-icons/io";
import Spinner from 'react-bootstrap/Spinner';
import AddTopics from './Options/AddDatas/AddTopics';
import EditTopics from './Options/EditDatas/EditTopics';
import AddSubTopics from './Options/AddDatas/AddSubTopics';
import EditSubTopics from './Options/EditDatas/EditSubTopics';
import AddMaterial from './Options/AddDatas/AddMaterial';
import DeleteTopics from './Options/DeleteDatas/DeleteTopics';
import DeleteSubTopics from './Options/DeleteDatas/DeleteSubTopics';
import { useLocation } from 'react-router-dom';
import DeleteMaterial from './Options/DeleteDatas/DeleteMaterial';
import EditMaterial from './Options/EditDatas/EditMaterial';
import { Alert, Button, } from 'react-bootstrap';
import AddMaterialToMiddle from './Options/AddDatas/AddMaterialToMiddle';
import CloneTopic from './Options/CloneDatas/CloneTopic';
import CloneSubTopic from './Options/CloneDatas/CloneSubTopic';
import CloneMaterial from './Options/CloneDatas/CloneMaterial';
import { Link } from 'react-router-dom';
import { FaBoxOpen } from 'react-icons/fa6';
import { FaClone } from 'react-icons/fa';
import CloneCourse from './Options/CloneDatas/CloneCourse';
import { FaDownload } from "react-icons/fa6";
import { FaUpload } from "react-icons/fa6";
import { BiSolidOffer } from "react-icons/bi";

const StudentJourney = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddTopic, setShowAddTopic] = useState(false);
    const [showCloneCourse, setShowCloneCourse] = useState(false);
    const [showCloneTopic, setShowCloneTopic] = useState(false);
    const [showEditTopic, setShowEditTopic] = useState(false);
    const [showDeleteTopic, setShowDeleteTopic] = useState(false);
    const [showAddSubTopic, setShowAddSubTopic] = useState(false);
    const [showDeleteSubTopic, setShowDeleteSubTopic] = useState(false);
    const [showCloneSubTopic, setShowCloneSubTopic] = useState(false);
    const [showEditSubTopic, setShowEditSubTopic] = useState(false);
    const [showAddMaterial, setShowAddMaterial] = useState(false);
    const [showAddMaterialToMiddle, setShowAddMaterialToMiddle] = useState(false);
    const [showDeleteMaterial, setShowDeleteMaterial] = useState(false);
    const [showEditMaterial, setShowEditMaterial] = useState(false);
    const [showCloneMaterial, setShowCloneMaterial] = useState(false);
    const [courseDetails, setCourseDetails] = useState(null);
    const [currentTopicIndex, setCurrentTopicIndex] = useState(null);
    const [currentCourseName, setCurrentCourseName] = useState('');
    const [currentTopicName, setCurrentTopicName] = useState('');
    const [currentTopicId, setCurrentTopicId] = useState('');
    const [currentSubTopicName, setCurrentSubTopicName] = useState('');
    const [currentSubTopicIndex, setCurrentSubTopicIndex] = useState(null);
    const [currentMaterialId, setCurrentMaterialId] = useState('');
    const [currentMaterialType, setCurrentMaterialType] = useState('');
    const [chapters, setChapters] = useState();
    const [currentPriority, setCurrentPriority] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEdited, setIsEdited] = useState(false)
    const [showSuccessCloneCourse, setShowSuccessCloneCourse] = useState(false);
    const [showSuccessCloneTopic, setShowSuccessCloneTopic] = useState(false);
    const [showSuccessCloneSubTopic, setShowSuccessCloneSubTopic] = useState(false);
    const [showSuccessCloneMaterial, setShowSuccessCloneMaterial] = useState(false);
    const [searchTopic, setSearchTopic] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [materialInputValues, setMaterialInputValues] = useState({});
    const [clickedButtons, setClickedButtons] = useState({});
    const [subTopicSearch, setSubTopicSearch] = useState({});

    const courseTitle = localStorage.getItem("CourseTitle")

    const extractCourseName = (path) => {
        const parts = path.split('/');
        return decodeURIComponent(parts[parts.length - 1]);
    };
    const courseName = extractCourseName(location.pathname);

    const handleAddCourse = async () => {
        try {
            const response = await axios.post(`/add-course`, {
                id: 0,
                course_id: `${courseName}`,
                topics: "?",
                subtopic: "?",
                material_type: "?",
                material_id: "?",
                priority: 0
            },{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });

            window.location.reload()
        } catch (error) {
            console.error('Error adding topic:', error);
        }
    };

    const handleAddSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
        } catch (error) {
            console.error('Error fetching course details:', error);
        }
    };

    const handleEditSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setIsEdited(true)
            setCourseDetails(response.data);
            setTimeout(() => {
                setIsEdited(false);
            }, 2000);
        } catch (error) {
            console.error('Error fetching course details:', error);
        }
    };

    const handleDeleteSuccess = async () => {
        setIsDeleting(true);
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
        } catch (error) {
            console.error('Error fetching course details:', error);
        } finally {
            setTimeout(() => {
                setIsDeleting(false);
            }, 2000);
        }
    };

    const handleCloneCourseSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
            // setShowSuccessCloneTopic(true);
            // setTimeout(() => setShowSuccessCloneTopic(false), 3000); 
        } catch (error) {
            console.error('Error fetching course details:', error);
        }


    };

    const handleCloneTopicSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
            setShowSuccessCloneTopic(true);
            setTimeout(() => setShowSuccessCloneTopic(false), 3000);
        } catch (error) {
            console.error('Error fetching course details:', error);
        }


    };

    const handleCloneSubTopicSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
            setShowSuccessCloneSubTopic(true);
            setTimeout(() => setShowSuccessCloneSubTopic(false), 3000);
        } catch (error) {
            console.error('Error fetching course details:', error);
        }

    };

    const handleCloneMaterialSuccess = async () => {
        try {
            const response = await axios.get(`/course/${courseName}`,{
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            setCourseDetails(response.data);
            setShowSuccessCloneMaterial(true);
            setTimeout(() => setShowSuccessCloneMaterial(false), 3000);
        } catch (error) {
            console.error('Error fetching course details:', error);
        }

    };

    useEffect(() => {
        const fetchCourseDetails = async () => {
            setLoading(true);

            try {
                const response = await axios.get(`/course/${courseName}`, {
                    headers: {
                        'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                    }
                });
                setCourseDetails(response.data);
                setError(null);
            } catch (error) {
                setError(error.response?.data?.error || 'An error occurred');
                setCourseDetails(null);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseDetails();
    }, [courseName]);

    if (loading) return <p style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}><Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /></p>;
    if (error) {
        console.log("error", error);
        return (
            <div>
                <h1 className={styles.courseName}>{courseTitle}</h1>
                <div className={styles.notFoundDiv}>
                    <lord-icon
                        src="https://cdn.lordicon.com/unukghxb.json"
                        trigger="morph"
                        state="morph-cross"
                        style={{ width: "250px", height: "250px" }}>
                    </lord-icon>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <strong>It's currently not available. Please create a new one.</strong>
                </div>
                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={handleAddCourse}>Add New Journey</Button>
                </div>
            </div>
        )
    }

    const handleShowAddTopic = () => setShowAddTopic(true);
    const handleCloseAddTopic = () => setShowAddTopic(false);

    const handleShowCloneCourse = (CourseId) => {
        setCurrentCourseName(CourseId)
        setShowCloneCourse(true);
    };
    const handleCloseCloneCourse = () => setShowCloneCourse(false);

    const handleShowCloneTopic = (topicName) => {
        setCurrentTopicName(topicName)
        setShowCloneTopic(true);
    };
    const handleCloseCloneTopic = () => setShowCloneTopic(false);

    const handleShowEditTopic = (id, topicName) => {
        setCurrentTopicId(id);
        setCurrentTopicName(topicName);
        setShowEditTopic(true);
    };
    const handleCloseEditTopic = () => setShowEditTopic(false);

    const handleShowDeleteTopic = (topicName) => {
        setCurrentTopicName(topicName);
        setShowDeleteTopic(true);
    };
    const handleCloseDeleteTopic = () => setShowDeleteTopic(false);

    const handleShowAddSubTopic = (topicIndex, topicName) => {
        setCurrentTopicIndex(topicIndex);
        setCurrentTopicName(topicName);
        setShowAddSubTopic(true);
    };
    const handleCloseAddSubTopic = () => setShowAddSubTopic(false);

    const handleShowEditSubTopic = (topicName, subTopicName) => {
        setCurrentTopicName(topicName);
        setCurrentSubTopicName(subTopicName);
        setShowEditSubTopic(true);
    }
    const handleCloseEditSubTopic = () => setShowEditSubTopic(false);

    const handleShowDeleteSubTopic = (topicName, subTopicName) => {
        setCurrentTopicName(topicName);
        setCurrentSubTopicName(subTopicName);
        setShowDeleteSubTopic(true);
    };
    const handleCloseDeleteSubTopic = () => setShowDeleteSubTopic(false);

    const handleShowCloneSubTopic = (topicName, subTopicName) => {
        setCurrentTopicName(topicName)
        setCurrentSubTopicName(subTopicName)
        setShowCloneSubTopic(true);
    };
    const handleCloseCloneSubTopic = () => setShowCloneSubTopic(false);

    const handleShowAddMaterial = (topicIndex, topicName, subTopicIndex, subTopicName) => {
        setCurrentTopicIndex(topicIndex);
        setCurrentTopicName(topicName);
        setCurrentSubTopicIndex(subTopicIndex);
        setCurrentSubTopicName(subTopicName);
        setShowAddMaterial(true);
    };
    const handleCloseAddMaterial = () => setShowAddMaterial(false);

    const handleShowAddMaterialToMiddle = (topicIndex, topicName, subTopicIndex, subTopicName, priority) => {
        setCurrentTopicIndex(topicIndex);
        setCurrentTopicName(topicName);
        setCurrentSubTopicIndex(subTopicIndex);
        setCurrentSubTopicName(subTopicName);
        setCurrentPriority(priority);
        setShowAddMaterialToMiddle(true);
    };
    const handleCloseAddMaterialToMiddle = () => setShowAddMaterialToMiddle(false);

    const handleShowDeleteMaterial = (topicName, subTopicName, materialId, materialType) => {
        setCurrentTopicName(topicName);
        setCurrentSubTopicName(subTopicName);
        setCurrentMaterialId(materialId);
        setCurrentMaterialType(materialType);
        setShowDeleteMaterial(true);
    };
    const handleCloseDeleteMaterial = () => setShowDeleteMaterial(false);

    const handleShowEditMaterial = (topicName, subTopicName, materialId, materialType, chapters) => {
        setCurrentTopicName(topicName);
        setCurrentSubTopicName(subTopicName);
        setCurrentMaterialId(materialId);
        setCurrentMaterialType(materialType);
        setChapters(chapters);
        setShowEditMaterial(true);
    };
    const handleCloseEditMaterial = () => setShowEditMaterial(false);

    const handleShowCloneMaterial = (topicName, subTopicName, materialId, materialType) => {
        setCurrentTopicName(topicName)
        setCurrentSubTopicName(subTopicName)
        setCurrentMaterialId(materialId)
        setCurrentMaterialType(materialType)
        setShowCloneMaterial(true);
    };
    const handleCloseCloneMaterial = () => setShowCloneMaterial(false);

    const handleAddNewMaterial = (materialType, topicName, subTopicName) => {
        const materialData = {
            courseName,
            currentTopicName: topicName,
            currentSubTopicName: subTopicName,
            materialType
        };
        localStorage.setItem('materialData', JSON.stringify(materialData));
    };


    const toggleDropdown = () => setIsOpen(!isOpen);


    const handleSearch = (e) => {
        setSearchTopic(e.target.value.toLowerCase());
    };

    const handleMaterialInputChange = (event, materialId) => {
        const newValue = event.target.value;

        setMaterialInputValues((prevValues) => ({
            ...prevValues,
            [materialId]: newValue,
        }));
    };

    const updatePriority = async (id, materialId, newPriority) => {
        try {
            const response = await axios.post('/update-serialNo', {
                id: id,
                material_id: materialId,
                priority: newPriority,
            }, {
                headers: {
                    'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                }
            });
            handleAddSuccess();
            if (response.data.success) {
                console.log('Priority updated successfully');
            } else {
                console.error('Failed to update priority:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating priority:', error);
        } finally {
            // Update the clicked state for this specific button
            setClickedButtons(prevState => ({
                ...prevState,
                [id]: true
            }));
        }
    };


    const filteredTopics = courseDetails.courses?.filter(c =>
        c.topic?.toLowerCase().includes(searchTopic)
    ) || [];

    const handleMaterialSearch = (e, subIndex) => {
        const value = e.target.value;
        setSubTopicSearch((prevSearch) => ({
            ...prevSearch,
            [subIndex]: value
        }));
    };




    return (
        <>
            {isDeleting ? (
                <div className={styles.deleteAnimation}>
                    <lottie-player
                        src="https://lottie.host/3a4f0b0a-a550-4e4b-a73e-9b537c545b4a/rlz0Iz8Y9d.json"
                        background="#FFFFFF"
                        style={{ width: '300px', height: '300px' }}
                        autoplay
                    ></lottie-player>
                </div>
            ) :
                <div className={styles.page}>
                    <h1 className={styles.courseName}>{courseTitle}</h1>
                    <div className={styles.addBtnDiv}>
                        <button className={styles.addBtn} title='Add Topic' onClick={handleShowAddTopic}>
                            <FaCirclePlus className={`${styles.icon}`} />
                        </button>
                        <button className={styles.addBtn} style={{ marginLeft: '10px' }} title='Clone Batch'>
                            <FaClone className={`${styles.icon}`} onClick={() => handleShowCloneCourse(courseDetails.courses)} />
                        </button>
                    </div>
                    <input
                        type="text"
                        placeholder="Search topics..."
                        value={searchTopic}
                        onChange={handleSearch}
                        className={styles.searchBar}
                    />
                    {showSuccessCloneTopic && (
                        <Alert
                            variant='success'
                            style={{ textAlign: "center" }}
                        >
                            <span>Cloned Topic SuccessFully</span>
                        </Alert>
                    )}
                    {showSuccessCloneSubTopic && (
                        <Alert
                            variant='success'
                            style={{ textAlign: "center" }}
                        >
                            <span>Cloned SubTopic SuccessFully</span>
                        </Alert>
                    )}
                    {showSuccessCloneMaterial && (
                        <Alert
                            variant='success'
                            style={{ textAlign: "center" }}
                        >
                            <span>Cloned Material SuccessFully</span>
                        </Alert>
                    )}
                    {isEdited && (
                        <Alert
                            variant='success'
                            style={{ textAlign: "center" }}
                        >
                            <span>Edited SuccessFully</span>
                        </Alert>
                    )}
                    {filteredTopics.length > 0 ? (

                        <Accordion className={styles.mainToggle}>
                            {filteredTopics.map((topic, index) => (
                                topic.topic && topic.topic.trim() !== '?' ? (
                                    <Accordion.Item eventKey={String(index)} key={index}>
                                        <Accordion.Header className={styles.accordianHeader}>{topic.topic}</Accordion.Header>
                                        <Accordion.Body>
                                            <div className={styles.header}>
                                                <div>
                                                    <strong>SUB TOPICS</strong>
                                                </div>
                                                <div className={styles.topicOptions}>
                                                    <button className={styles.cloneBtn} title='Clone Topic' onClick={() => handleShowCloneTopic(topic.topic)}>
                                                        <PiCopySimpleFill className={styles.icon} />
                                                    </button>
                                                    <button className={styles.addBtn} title='Add SubTopic' onClick={() => handleShowAddSubTopic(index, topic.topic)}>
                                                        <FaCirclePlus className={styles.icon} />
                                                    </button>
                                                    <button className={styles.editBtn} title='Edit Topic' onClick={() => handleShowEditTopic(topic.id, topic.topic)}>
                                                        <MdEdit className={styles.icon} />
                                                    </button>
                                                    <button className={styles.deleteBtn} title='Delete Topic' onClick={() => handleShowDeleteTopic(topic.topic)}>
                                                        <MdDelete className={styles.icon} />
                                                    </button>
                                                </div>
                                            </div>
                                            <Accordion>
                                                {topic.subTopic.length > 0 ? (
                                                    topic.subTopic.map((subTopic, subIndex) => (
                                                        subTopic.title.trim() !== "?" ? (
                                                            <Accordion.Item eventKey={String(subIndex)} key={subIndex}>
                                                                <Accordion.Header className={styles.accordianHeader}>{subTopic.title === "" ?
                                                                    topic.topic : subTopic.title}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className={styles.header}>
                                                                        <div>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Search materials..."
                                                                                value={subTopicSearch[subIndex] || ''}
                                                                                onChange={(e) => handleMaterialSearch(e, subIndex)}
                                                                                className={styles.materialSearchBar}
                                                                            />
                                                                        </div>
                                                                        <div className={styles.topicOptions}>
                                                                            <button className={styles.cloneBtn}>
                                                                                <PiCopySimpleFill className={styles.icon} title='Clone SubTopic' onClick={() => handleShowCloneSubTopic(topic.topic, subTopic.title)} />
                                                                            </button>
                                                                            <div className={styles.dropdown}>
                                                                                <button onClick={toggleDropdown} className={styles.addBtn} style={{ cursor: 'pointer' }}>
                                                                                    <MdAddBox className={styles.icon} title="Add New Data" />
                                                                                </button>
                                                                                {isOpen && (
                                                                                    <div className={styles.dropdownMenu}>
                                                                                        <Link
                                                                                            to="/addMaterialPDF"
                                                                                            className={styles.newPdf}
                                                                                            onClick={() => handleAddNewMaterial('pdf', topic.topic, subTopic.title)}
                                                                                        >
                                                                                            Add New PDF
                                                                                        </Link>
                                                                                        <Link
                                                                                            to="/addMaterialVideo"
                                                                                            className={styles.newPdf}
                                                                                            onClick={() => handleAddNewMaterial('video', topic.topic, subTopic.title)}
                                                                                        >
                                                                                            Add New Video
                                                                                        </Link>
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <button className={styles.addBtn}>
                                                                                <FaCirclePlus className={styles.icon} title='Add Data' onClick={() => handleShowAddMaterial(index, topic.topic, subIndex, subTopic.title)} />
                                                                            </button>
                                                                            <button className={styles.editBtn} title='Edit SubTopic' onClick={() => handleShowEditSubTopic(topic.topic, subTopic.title)}>
                                                                                <MdEdit className={styles.icon} />
                                                                            </button>
                                                                            <button className={styles.deleteBtn} title='Delete SubTopic' onClick={() => handleShowDeleteSubTopic(topic.topic, subTopic.title)}>
                                                                                <MdDelete className={styles.icon} />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div>
                                                                            {(() => {
                                                                                const filteredItems = subTopic.data.filter((item) =>
                                                                                    item.caption.toLowerCase().includes((subTopicSearch[subIndex] || '').toLowerCase())
                                                                                );

                                                                                if (filteredItems.length === 0) {
                                                                                    return (
                                                                                        <div className={styles.NoData}>
                                                                                            <div className={styles.flipContainer}>
                                                                                                <div title="icon-horizontal-flip">
                                                                                                    <FaFaceFrownOpen className={`${styles.flipIcon} fs-1`} />
                                                                                                </div>
                                                                                                <div>No Materials Available</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }

                                                                                return (
                                                                                    <div className="row">
                                                                                        {filteredItems.map((item, itemIndex) => (
                                                                                            item.id && item.id.trim() !== '?' ? (
                                                                                                <div className='col-lg-4 col-md-6' key={itemIndex}>
                                                                                                    <div className={styles.item}>
                                                                                                        <div className='row'>
                                                                                                            <div className='col-1'>
                                                                                                                <div className={styles.itemNumber}>
                                                                                                                    {itemIndex + 1}
                                                                                                                </div>
                                                                                                                {item.demo === "1" ? (
                                                                                                                    <div style={{ color: "#FFC107" }}>
                                                                                                                    <BiSolidOffer className='fs-4' />
                                                                                                                </div>
                                                                                                                ) : null }
                                                                                                            </div>
                                                                                                            <div className='col-1' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                                                {item.material_type === "pdf" || item.type === "pdf" ? (
                                                                                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/learning_materials/${item.link}`} target="_blank" rel="noopener noreferrer">
                                                                                                                        <IoIosDocument className='fs-4' />
                                                                                                                    </a>
                                                                                                                ) : item.material_type === "video" || item.type === "video" ? (
                                                                                                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                                                                                                        <MdOndemandVideo className='fs-4' />
                                                                                                                    </a>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                            <div className='col-6'>
                                                                                                                <div className={styles.detailsBar}>
                                                                                                                    <div className="row" style={{ display: 'flex' }}>
                                                                                                                        <div className='col-12'>
                                                                                                                            <strong>ID : {item.id}</strong>
                                                                                                                        </div>
                                                                                                                        <div className='col-12'>
                                                                                                                            <p>{item.caption}</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className={styles.optionsDiv}>
                                                                                                                    <div className={styles.options}>
                                                                                                                        <button className={styles.cloneMt} title="Clone">
                                                                                                                            <PiCopySimpleFill className={styles.smallIcon} onClick={() => handleShowCloneMaterial(topic.topic, subTopic.title, item.material_id || item.id, item.material_type || item.type)} />
                                                                                                                        </button>
                                                                                                                        <button className={styles.editMt} title="Edit" onClick={() => handleShowEditMaterial(topic.topic, subTopic.title, item.material_id || item.id, item.material_type || item.type, item.chapters)}>
                                                                                                                            <MdEdit className={styles.smallIcon} />
                                                                                                                        </button>
                                                                                                                        <button className={styles.deleteMt} title="Delete" onClick={() => handleShowDeleteMaterial(topic.topic, subTopic.title, item.material_id || item.id, item.material_type || item.type)}>
                                                                                                                            <MdDelete className={styles.smallIcon} />
                                                                                                                        </button>
                                                                                                                        <input
                                                                                                                            type="number"
                                                                                                                            value={materialInputValues[item._id] || item.priority || ''}
                                                                                                                            onChange={(e) => handleMaterialInputChange(e, item._id)}
                                                                                                                            onWheel={(e) => e.target.blur()} // Disables scrolling on input
                                                                                                                            className={styles.slNoInputField}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='col-4'>
                                                                                                                <div className={styles.addMtDiv}>
                                                                                                                    <button className={styles.addMt}>
                                                                                                                        <FaCirclePlus className={styles.icon} title='Add Data after this material' onClick={() => handleShowAddMaterialToMiddle(index, topic.topic, subIndex, subTopic.title, item.priority)} />
                                                                                                                    </button>
                                                                                                                        </div>
                                                                                                                    {item.material_type === "video" || item.type === "video" ? (
                                                                                                                        <>
                                                                                                                        <div className={item.transcode_status === "Pending" ? styles.statusPending : item.transcode_status === "In Progress" ? styles.statusInProgress : styles.status}>
                                                                                                                <FaUpload />{item.transcode_status}
                                                                                                                </div>
                                                                                                                <div className={item.download_status === "Pending" ? styles.statusPending : item.download_status === "In Progress" ? styles.statusInProgress : styles.status}>
                                                                                                                <FaDownload />{item.download_status}
                                                                                                                </div>
                                                                                                                        </>
                                                                                                                ) : null}
                                                                                                                <button
                                                                                                                    className={`${styles.updateSlNo} ${clickedButtons[item._id] ? styles.clicked : ''}`}
                                                                                                                    onClick={() => updatePriority(item._id, item.id, materialInputValues[item._id] || item.priority)}
                                                                                                                >
                                                                                                                    {clickedButtons[item._id] ? "Updated" : "Update SL"}
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null
                                                                                        ))}
                                                                                    </div>
                                                                                );
                                                                            })()}
                                                                        </div>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        ) : (
                                                            <div className={styles.NoData}>

                                                                {/* <div className={styles.flipContainer}>
                                                                    <div title="icon-horizontal-flip">
                                                                        <FaFaceFrownOpen className={`${styles.flipIcon} fs-1`} />
                                                                    </div>
                                                                    <div>No Sub Topics Available</div>
                                                                </div> */}
                                                            </div>
                                                        )
                                                    ))
                                                ) : null}
                                            </Accordion>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ) : null
                            ))}
                        </Accordion>
                    ) :
                        <div className={styles.emptyStateContainer}>
                            <FaBoxOpen className={styles.emptyIcon} />
                            <strong>No Data Available</strong>
                        </div>
                    }



                    <AddTopics show={showAddTopic} onHide={handleCloseAddTopic} courseName={courseName} onSuccess={handleAddSuccess} />
                    <EditTopics show={showEditTopic} onHide={handleCloseEditTopic} courseName={courseName} currentTopicName={currentTopicName} onSuccess={handleEditSuccess} />
                    <DeleteTopics show={showDeleteTopic} onHide={handleCloseDeleteTopic} courseName={courseName} onSuccess={handleDeleteSuccess} topicName={currentTopicName} />
                    <CloneTopic show={showCloneTopic} onHide={handleCloseCloneTopic} courseName={courseName} topic={currentTopicName} onSuccess={handleCloneTopicSuccess} />
                    <CloneCourse show={showCloneCourse} onHide={handleCloseCloneCourse} courseName={courseName} topic={currentTopicName} onSuccess={handleCloneCourseSuccess} />
                    <AddSubTopics show={showAddSubTopic} onHide={handleCloseAddSubTopic} courseName={courseName} onAddSubTopicSuccess={handleAddSuccess} currentTopicName={currentTopicName} />
                    <EditSubTopics show={showEditSubTopic} onHide={handleCloseEditSubTopic} courseName={courseName} currentTopicName={currentTopicName} currentSubTopic={currentSubTopicName} onSuccess={handleEditSuccess} />
                    <DeleteSubTopics show={showDeleteSubTopic} onHide={handleCloseDeleteSubTopic} courseName={courseName} topicName={currentTopicName} subTopicName={currentSubTopicName} onSuccess={handleDeleteSuccess} />
                    <CloneSubTopic show={showCloneSubTopic} onHide={handleCloseCloneSubTopic} courseName={courseName} topic={currentTopicName} subTopic={currentSubTopicName} onSuccess={handleCloneSubTopicSuccess} />
                    <AddMaterial courseName={courseName} currentTopicName={currentTopicName} currentSubTopicName={currentSubTopicName} show={showAddMaterial} onHide={handleCloseAddMaterial} onSuccess={handleAddSuccess} />
                    <AddMaterialToMiddle courseName={courseName} currentTopicName={currentTopicName} currentSubTopicName={currentSubTopicName} currentPriority={currentPriority} show={showAddMaterialToMiddle} onHide={handleCloseAddMaterialToMiddle} onSuccess={handleAddSuccess} />
                    <DeleteMaterial show={showDeleteMaterial} onHide={handleCloseDeleteMaterial} courseName={courseName} topicName={currentTopicName} subTopicName={currentSubTopicName} materialId={currentMaterialId} materialType={currentMaterialType} onSuccess={handleDeleteSuccess} />
                    <EditMaterial show={showEditMaterial} onHide={handleCloseEditMaterial} courseName={courseName} currentTopicName={currentTopicName} currentSubTopicName={currentSubTopicName} currentMaterialId={currentMaterialId} currentMaterialType={currentMaterialType} chapters={chapters} onSuccess={handleEditSuccess} />
                    <CloneMaterial show={showCloneMaterial} onHide={handleCloseCloneMaterial} courseName={courseName} topic={currentTopicName} subTopic={currentSubTopicName} id={currentMaterialId} type={currentMaterialType} onSuccess={handleCloneMaterialSuccess} />

                </div>

            }
        </>
    );
};

export default StudentJourney;
