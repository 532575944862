import React, { useEffect, useState } from 'react';
import styles from '../../styles/convert.module.css';
import axios from '../../constents/axios';
import { Spinner, Alert, Form, Button, Modal, ProgressBar } from 'react-bootstrap';
import { CgSync } from "react-icons/cg";

const ConvertStudentJourney = () => {
    const [data, setData] = useState([]);
    const [courses, setCourses] = useState([]);
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [show, setShow] = useState(false);
    const [isConverting, setIsConverting] = useState(false);
    const [successResponse, setSuccessResponse] = useState(null);
    const [existData, setExistData] = useState(null);
    const [newData, setNewData] = useState(null);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('/fetch-label', {
                    headers: {
                        'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                    }
                });
                setData(response.data.data);
            } catch (error) {
                setError(error.response ? error.response.data.message : error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchCourses = async () => {
            if (selectedItem !== null && selectedType) {
                const selectedLabel = data[selectedItem]?.label;
                try {
                    const response = await axios.get(`/courseId/${selectedLabel}`, {
                        headers: {
                            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                        }
                    });
                    setCourses(response.data.data);
                } catch (error) {
                    setError(error.response ? error.response.data.message : error.message);
                }
            } else {
                setCourses([]);
            }
        };
    
        fetchCourses();
    }, [selectedItem, selectedType, data]);
    
    useEffect(() => {
        const fetchTopics = async () => {
            if (selectedCourse && selectedType) {
                const requestData = {
                    course_id: selectedCourse,
                    type: selectedType
                };
                try {
                    const response = await axios.post(`/getTopics`, requestData, {
                        headers: {
                            'Content-Type': 'application/json',
                            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`
                        },
                    });
                    setTopics(response.data.data);
                } catch (error) {
                    setError(error.response ? error.response.data.message : error.message);
                }
            } else {
                setTopics([]);
            }
        };
    
        fetchTopics();
    }, [selectedCourse, selectedType]);
    
    

    const handleItemClick = (index) => {
        if (selectedItem === index) {
            setSelectedItem(null);
            setCourses([]);
            setTopics([]);
            setSelectedCourse('');
            setSelectedTopic('');
        } else {
            setSelectedItem(index);
        }
    };

    const handleSelectChange = (value) => {
        setSelectedCourse(value);
    };

    const handleTopicChange = (value) => {
        setSelectedTopic(value);
    };

    const handleTypeChange = (value) => {
        setSelectedType(value);
    };

    const handleClose = () => setShow(false);

    const handleConvertion = async (e) => {
        e.preventDefault();
        if (selectedItem !== null && selectedCourse !== '' && selectedTopic !== '') {
            setIsConverting(true);
            setProgress(0);

            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const newProgress = oldProgress + 25;
                    return newProgress >= 100 ? 100 : newProgress;
                });
            }, 500);

            const requestData = {
                course_id: selectedCourse,
                type: selectedType,
                topic: selectedTopic,
            };

            try {
                const response = await axios.post('/convertToStudentJourney', requestData, {
                    headers: {
                        'Content-Type': 'application/json',
                         'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`
                    },
                });
                setSuccessResponse(response.data.message);
                setExistData(response.data.alreadyExists);
                setNewData(response.data.insertedMaterials);
                setShow(true);
            } catch (error) {
                console.error("API Error:", error.response ? error.response.data : error);
                const errorMessage = error.response ? error.response.data.message : error.message;
                setError(errorMessage);
            } finally {
                clearInterval(interval);
                setProgress(100);
                setIsConverting(false);
            }
        }
    };

    if (loading) return <div><Spinner animation="border" /></div>;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    return (
        <div>
            <div className={styles.header}>
                <h1><strong>Convert to Student Journey</strong></h1>
            </div>
            <div className={styles.form}>
                <Form onSubmit={handleConvertion}>
                    {/* Label Selection */}
                    <Form.Label>Select a label for conversion</Form.Label>
                    <Form.Select
                        onChange={(e) => handleItemClick(Number(e.target.value))}
                        className={styles.select}
                        required
                    >
                        <option value="">Choose a label</option>
                        {data.map((item, index) => (
                            <option key={index} value={index}>
                                {item.label}
                            </option>
                        ))}
                    </Form.Select>

                    {/* Conversion Type */}
                    <Form.Label>Select conversion type</Form.Label>
                    <Form.Select
                        onChange={(e) => handleTypeChange(e.target.value)}
                        className={styles.select} required>
                        <option value="">Choose a type</option>
                        <option value="pdf">PDFs</option>
                        <option value="video">Videos</option>
                    </Form.Select>
                    
                    {/* Course Selection */}
                    <Form.Label>Select a course to convert</Form.Label>
                    <Form.Select
                        onChange={(e) => handleSelectChange(e.target.value)}
                        className={styles.select}
                        required
                        disabled={courses.length === 0}
                    >
                        <option value="">Choose a course</option>
                        {selectedItem !== null &&
                            courses.map((course) => (
                                <option key={course.id} value={course.course_id}>
                                    {course.title}
                                </option>
                            ))
                        }
                    </Form.Select>


                    {/* Topic Selection */}
                        <>
                            <Form.Label>Select a topic</Form.Label>
                            <Form.Select
                                onChange={(e) => handleTopicChange(e.target.value)}
                                className={styles.select}
                                required
                                disabled={topics.length === 0}
                                >
                                <option value="">Choose a topic</option>
                                {selectedCourse !== null &&
                                topics.map((topic) => (
                                    <option key={topic.topics} value={topic.topics}>
                                        {topic.topics}
                                    </option>
                                ))
                            }
                            </Form.Select>
                        </>



                    {/* Convert Button */}
                    <div className={styles.convertButton}>
                        <Button type="submit" variant='primary' disabled={isConverting}>
                            Convert <CgSync />
                        </Button>
                    </div>

                    {/* Progress Bar */}
                    {isConverting && (
                        <>
                            <strong>Conversion in progress...</strong>
                            <ProgressBar now={progress} label={`${progress}%`} />
                        </>
                    )}
                </Form>
            </div>

            {/* Modal */}
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Conversion Completed</Modal.Title>
                    <img className={styles.finishLine} src="/finish-line.gif" alt="Completion Celebration" />
                </Modal.Header>
                <Modal.Body className={styles.modalBody}>
                    <div>
                        <strong>{successResponse}</strong>
                    </div>
                    {/* <div>
                        <span>Already converted: {existData} records</span>
                    </div> */}
                    <div>
                        <span>Newly created: {newData} records</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConvertStudentJourney;
