import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import axios from '../../constents/axios'; // Use your configured Axios instance

const UploadReel = ({ show, handleClose, onSuccess }) => {
  const [formState, setFormState] = useState({
    link: '',
    topic: '',
    description: '',
    isFreeReel: false,
    selectedSubject: '',
  });
  const [subjects, setSubjects] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });

  const resetForm = () => {
    setFormState({
      link: '',
      topic: '',
      description: '',
      isFreeReel: false,
      selectedSubject: '',
    });
    setMessage({ type: '', text: '' });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setMessage({ type: '', text: '' }); // Clear error message on input change
  };

  const handleUpload = async () => {
    const { link, topic, description, isFreeReel, selectedSubject } = formState;

    if (!link || !topic || !description || !selectedSubject) {
      setMessage({ type: 'error', text: 'Please fill in all fields before uploading.' });
      return;
    }

    setIsUploading(true);
    try {
      await axios.post('/createReel', {
        reel: link,
        topic,
        description,
        isFree: isFreeReel,
        subject: selectedSubject,
      });

      setMessage({ type: 'success', text: 'Reel uploaded successfully!' });
      onSuccess();
      resetForm();
      handleClose();
    } catch (err) {
      console.error('Error uploading reel:', err);
      setMessage({ type: 'error', text: 'Failed to upload reel. Please try again.' });
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const endpoint = formState.isFreeReel ? '/getFreeSubjects' : '/getPremiumSubjects';
        const { data } = await axios.get(endpoint);
        setSubjects(data);
      } catch (err) {
        console.error('Error fetching subjects:', err);
        setSubjects([]);
      }
    };
    fetchSubjects();
  }, [formState.isFreeReel]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload New Reel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isUploading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
            <span className="ms-2">Uploading...</span>
          </div>
        ) : (
          <>
            {message.text && (
              <Alert variant={message.type === 'error' ? 'danger' : 'success'} className="mt-2">
                {message.text}
              </Alert>
            )}
            <Form>
              <Form.Group className="mt-3">
                <Form.Label>Reel Link</Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={formState.link}
                  onChange={handleInputChange}
                  placeholder="Enter video link"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Topic</Form.Label>
                <Form.Control
                  type="text"
                  name="topic"
                  value={formState.topic}
                  onChange={handleInputChange}
                  placeholder="Enter topic"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                  placeholder="Enter description"
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Check
                  type="checkbox"
                  name="isFreeReel"
                  label="Free Reel"
                  checked={formState.isFreeReel}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group className="mt-3">
                <Form.Label>Choose Subject</Form.Label>
                <Form.Control
                  as="select"
                  name="selectedSubject"
                  value={formState.selectedSubject}
                  onChange={handleInputChange}
                >
                  <option value="">Select Subject</option>
                  {subjects.map((subject, index) => (
                    <option key={index} value={subject.subject}>
                      {subject.subject}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isUploading}>
          Close
        </Button>
        {!isUploading && (
          <Button variant="primary" onClick={handleUpload}>
            Save Changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default UploadReel;
