import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from '../../styles/sidebar.module.css';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
  CDBSidebarFooter,
} from 'cdbreact';
import { Modal, Button } from 'react-bootstrap';
import Login from '../Login/Login';
import userImage from './user.png';
import axios from '../../constents/axios';
import { FaArrowRight } from 'react-icons/fa';  // Import an arrow icon

const SideBar = ({ user, setUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();  // Get the current URL

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleLogOut = () => {
    setIsLoggingOut(true);
  };

  const handleTranscode = async () => {
    try {
      await axios.get('/transcode');
    } catch (error) {
      console.error('Error starting transcoding server:', error.message);
    }
  };

  useEffect(() => {
    if (isLoggingOut) {
      localStorage.removeItem('adminLogin');
      setUser(null);
      setShowModal(false);
      setIsLoggingOut(false);
    }
  }, [isLoggingOut]);

  const isActiveLink = (path) => {
    return location.pathname === path;
  };

  return (
    <div className={styles.sidebarDiv}>
      <CDBSidebar className={styles.sidebar}>
        <CDBSidebarHeader prefix={<i className="fa fa-bars" />}>Admin Panel</CDBSidebarHeader>
        <CDBSidebarContent className={styles.menuBar}>
          <CDBSidebarMenu className={styles.menuItem}>
            <Link to="/studentJourney" className={styles.link}>
              <CDBSidebarMenuItem className={isActiveLink('/studentJourney') ? styles.activeItem : styles.item} icon="th-large">
                Student Journey
                {isActiveLink('/studentJourney') && <FaArrowRight fontSize={20} style={{ marginLeft: '25px' }} />}
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/e-books" className={styles.link}>
              <CDBSidebarMenuItem className={isActiveLink('/e-books') ? styles.activeItem : styles.item} icon="book">
                E-Books
                {isActiveLink('/e-books') && <FaArrowRight fontSize={20} style={{ marginLeft: '80px' }} />}
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/convertStudentJourney" className={styles.link}>
              <CDBSidebarMenuItem className={isActiveLink('/convertStudentJourney') ? styles.activeItem : styles.item} icon="sync">
                Convertion
                {isActiveLink('/convertStudentJourney') && <FaArrowRight fontSize={20} style={{ marginLeft: '40px' }} />}
              </CDBSidebarMenuItem>
            </Link>
            <Link to="/reels" className={styles.link}>
              <CDBSidebarMenuItem className={isActiveLink('/reels') ? styles.activeItem : styles.item} icon="play">
                Reels
                {isActiveLink('/reels') && <FaArrowRight fontSize={20} style={{ marginLeft: '40px' }} />}
              </CDBSidebarMenuItem>
            </Link>
            <Link className={styles.link}>
              <CDBSidebarMenuItem
                className={styles.item}
                icon="pallet"
                onClick={toggleDropdown}
              >
                Tests
              </CDBSidebarMenuItem>
              {isOpen && (
                <div className={styles.dropdown}>
                  <ul>
                    <Link to="/Section" className={styles.link}>
                      <CDBSidebarMenuItem className={isActiveLink('/Section') ? styles.activeItem : styles.item} icon="columns">
                        Section
                        {isActiveLink('/Section') && <FaArrowRight fontSize={20} style={{ marginLeft: '40px' }} />}
                      </CDBSidebarMenuItem>
                    </Link>
                    <Link to="/Questions" className={styles.link}>
                      <CDBSidebarMenuItem className={isActiveLink('/Questions') ? styles.activeItem : styles.item} icon="question-circle">
                        Questions
                        {isActiveLink('/Questions') && <FaArrowRight fontSize={20} style={{ marginLeft: '40px' }} />}
                      </CDBSidebarMenuItem>
                    </Link>
                    <Link to="/test" className={styles.link}>
                      <CDBSidebarMenuItem className={isActiveLink('/test') ? styles.activeItem : styles.item} icon="clipboard-list">
                        Test
                        {isActiveLink('/test') && <FaArrowRight fontSize={20} style={{ marginLeft: '40px' }} />}
                      </CDBSidebarMenuItem>
                    </Link>
                  </ul>
                </div>
              )}
            </Link>
          </CDBSidebarMenu>
        </CDBSidebarContent>
        <CDBSidebarFooter className={styles.footer} style={{ textAlign: 'center' }}>
          <Link className={styles.link} onClick={handleTranscode}>
            <CDBSidebarMenuItem className={styles.item} icon="sync">
              Start Transcode
            </CDBSidebarMenuItem>
          </Link>
          {user && (
            <CDBSidebarMenu className={styles.menuItem}>
              <CDBSidebarMenuItem className={styles.item} icon="user" onClick={handleShow}>
                <strong>{user.name}</strong>
              </CDBSidebarMenuItem>
            </CDBSidebarMenu>
          )}
        </CDBSidebarFooter>
      </CDBSidebar>
      <div className={styles.OutletDiv}>
        <div className="col py-3 p-0 m-0" style={{ flexGrow: '1' }}>
          <Outlet />
        </div>
      </div>

      {user ? (
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-md-3'>
                <div>
                  <img className={styles.userImageDiv} src={userImage} alt={user.name} />
                </div>
              </div>
              <div className='col-md-9'>
                <p>Name: {user.name}</p>
                <p>Email: {user.username}</p>
                <p>Department: {user.department}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleLogOut}>
              Log Out
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default SideBar;
