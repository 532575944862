import { Button, Modal, Form, Spinner, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from '../../../../constents/axios';
import styles from '../../../../styles/clone.module.css';
import Select from 'react-select';

const CloneTopic = ({ show, onHide, courseName, topic, onSuccess }) => {
    const [labels, setLabels] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState(null); // Update to work with react-select
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const toggleSelectAll = () => {
        if (selectedCourses.length === courses.length) {
            setSelectedCourses([]);
        } else {
            setSelectedCourses(courses.map(course => course.course_id));
        }
    };

    const toggleCourse = (courseId) => {
        setSelectedCourses((prev) =>
            prev.includes(courseId) ? prev.filter((id) => id !== courseId) : [...prev, courseId]
        );
    };

    const handleLabelChange = (selectedOption) => {
        setSelectedLabel(selectedOption); // react-select expects selectedOption to be an object
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectedLabel || selectedCourses.length === 0) {
            setError('Please select a label and at least one course.');
            return;
        }

        const requestData = {
            course_id: courseName,
            topic: topic,
            cloneTo: selectedCourses,
        };

        axios.post('/clone-topic', requestData, {
            headers: {
                'Content-Type': 'application/json',
                'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
            }
        })
        .then(response => {
            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            return response.data;
        })
        .then(data => {
            setShowSuccessMessage(true);
            setSelectedLabel(null);
            setSelectedCourses([]);
            setError(null);
            onSuccess(); // Call the onSuccess callback
            onHide();
        })
        .catch(error => {
            console.error('Error cloning topic:', error);
            setError(error.response ? error.response.data.error : error.message);
        });
    };

    useEffect(() => {
        const fetchLabels = async () => {
            try {
                const response = await axios.get('/fetch-label', {
                    headers: {
                        'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                    }
                });
                const labelOptions = response.data.data.map((label) => ({
                    value: label.label,
                    label: label.label,
                }));
                setLabels(labelOptions);
            } catch (error) {
                console.error("There was an error making the request:", error);
                setError(error.response ? error.response.data : error.message);
            } finally {
                setLoading(false);
            }
        };

        if (selectedLabel) {
            const fetchCourses = async () => {
                setLoading(true);
                try {
                    const response = await axios.post('/fetch-courseId-label', { label: selectedLabel.value }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
                        }
                    });
                    setCourses(response.data.result);
                } catch (error) {
                    console.error("There was an error making the request:", error);
                    setError(error.response ? error.response.data : error.message);
                } finally {
                    setLoading(false);
                }
            };
            fetchCourses();
        }

        fetchLabels();
    }, [selectedLabel]);

    return (
        <div>
            <Modal show={show} size="md" onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Clone Topic</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {loading && <Spinner animation="border" />}
                        {error && <Alert variant="danger">{error}</Alert>}
                        {!loading && (
                            <>
                                <Select
                                    placeholder="Select Label"
                                    value={selectedLabel}
                                    onChange={handleLabelChange}
                                    options={labels}
                                    isClearable
                                />
                                {courses?.length > 0 && (
                                    <div>
                                        <Form.Check
                                            type="checkbox"
                                            label="Select All"
                                            checked={selectedCourses.length === courses.length}
                                            onChange={toggleSelectAll}
                                            className={styles.selectAll}
                                        />
                                        {courses?.map((course) => (
                                            <div key={course.course_id} className={styles.checkBox}>
                                                <Form.Check
                                                    type="checkbox"
                                                    label={course.title}
                                                    checked={selectedCourses.includes(course.course_id)}
                                                    onChange={() => toggleCourse(course.course_id)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>Close</Button>
                        <Button variant="primary" type="submit">Clone Submit</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
};

export default CloneTopic;
