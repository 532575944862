import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from '../../../constents/axios';
import AWS from 'aws-sdk';

const EditModal = ({ show, onHide, ebook, onSuccess }) => {
    const [caption, setCaption] = useState('');
    const [banner, setBanner] = useState('');
    const [file, setFile] = useState('');
    const [bannerFile, setBannerFile] = useState(null);
    const [ebookFile, setEbookFile] = useState(null);
    const [imageError, setImageError] = useState('');
    const [pdfError, setPdfError] = useState('');
    const [isModified, setIsModified] = useState(false);

    const [initialCaption, setInitialCaption] = useState('');
    const [initialBanner, setInitialBanner] = useState('');
    const [initialFile, setInitialFile] = useState('');

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    const uploadToS3 = (file, folder) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                Key: `${folder}/${year}/${month}/${day}/${file.name}`,  
                Body: file,
                ContentType: file.type,
                ACL: 'public-read',
            };
            s3.upload(params, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve({
                        folder: folder,
                        fileName: file.name,
                        location: data.Location
                    });
                }
            });
        });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            let bannerData = { folder: '', fileName: '', location: banner };  
            let fileData = { folder: '', fileName: '', location: file };      

            if (bannerFile) {
                bannerData = await uploadToS3(bannerFile, 'QBook');
            }
            if (ebookFile) {
                fileData = await uploadToS3(ebookFile, 'QBook');
            }

            const updatedEbook = {
                ...ebook,
                caption,
                banner: `${bannerData.folder}/${year}/${month}/${day}/${bannerData.fileName}`,
                file: `${fileData.folder}/${year}/${month}/${day}/${fileData.fileName}`,
            };

            await axios.put(`/updateEbook/${ebook.id}`, updatedEbook);
            onSuccess();
        } catch (error) {
            console.error('Error updating eBook:', error);
        }
        onHide();
    };

    useEffect(() => {
        if (ebook) {
            setCaption(ebook.caption);
            setBanner(ebook.banner);
            setFile(ebook.file);
            setInitialCaption(ebook.caption);
            setInitialBanner(ebook.banner);
            setInitialFile(ebook.file);
        }
        const hasChanged = caption !== initialCaption || bannerFile !== null || ebookFile !== null;
        setIsModified(hasChanged);
    }, [ebook, caption, bannerFile, ebookFile, initialCaption]);

    return (
        <Modal show={show} backdrop="static" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit eBook</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSave}>
                <Modal.Body>
                    <Form.Group controlId="formCaption">
                        <Form.Label>Caption</Form.Label>
                        <Form.Control
                            type="text"
                            value={caption}
                            required
                            onChange={(e) => setCaption(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBanner" className="mt-3">
                        <Form.Label>Banner</Form.Label>
                        {banner && <Form.Text className="d-block mb-2">Current: <p href={banner} target="_blank" rel="noopener noreferrer">{banner}</p></Form.Text>}
                        <Form.Control
                            type="file"
                            accept="image/*"
                            isInvalid={!!imageError}  
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file && file.type.startsWith('image/')) {
                                    setBannerFile(file);
                                    setImageError(''); 
                                } else {
                                    setImageError('Please select a valid image file.');
                                    e.target.value = null; 
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{imageError}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formFile" className="mt-3">
                        <Form.Label>File</Form.Label>
                        {file && <Form.Text className="d-block mb-2">Current: <p href={file} target="_blank" rel="noopener noreferrer">{file}</p></Form.Text>}
                        <Form.Control
                            type="file"
                            accept=".pdf"
                            isInvalid={!!pdfError}  
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file && file.type === 'application/pdf') {
                                    setEbookFile(file);
                                    setPdfError(''); 
                                } else {
                                    setPdfError('Please select a valid PDF file.');
                                    e.target.value = null; 
                                }
                            }}
                        />
                        <Form.Control.Feedback type="invalid">{pdfError}</Form.Control.Feedback>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={!isModified}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default EditModal;
