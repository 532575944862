import React, { useEffect, useState } from 'react';
import styles from "../../styles/login.module.css";
import { Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { PiEyeClosed } from "react-icons/pi";
import axios from '../../constents/axios';
import { jwtDecode } from 'jwt-decode'; 

const Login = ({ onLoginSuccess }) => {
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const loggedIn = localStorage.getItem('adminLogin');
        if (!loggedIn) {
            setShowModal(true);
        }
    }, []);

    const handleCloseModal = () => setShowModal(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post('/login', { username, password },{
            headers: {
                'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
            }
        });
          const { message, token } = response.data;
    
          if (message === 'Login successful') {
            localStorage.setItem('adminLogin', token);
            setShowModal(false);
            setError('');
            const decodedToken = jwtDecode(token);
            onLoginSuccess(decodedToken);
    
          } else {
            setError(message);
          }
        } catch (error) {
          setError('Username or Password is not found');
        }
      };

    return (
        showModal && (
            <Modal show={showModal} onHide={handleCloseModal} backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered animation={true}>
                <div className={styles.loginHead}>
                    <Modal.Header>
                        <Modal.Title>Login</Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <Form onSubmit={handleLogin}>
                        <Form.Group className="mb-3" controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                value={username}
                                required
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Enter your password"
                                    value={password}
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                    {showPassword ? <FaEye /> : <PiEyeClosed />}
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        {error && <p className={styles.error}>{error}</p>}
                        <Modal.Footer>
                            <Button type="submit" variant="primary">Login</Button>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    );
}

export default Login;
