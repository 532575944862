import React, { useState, useEffect } from 'react';
import styles from '../../styles/ebook.module.css';
import { Button, Nav, Tab, Table } from 'react-bootstrap';
import { MdOutlineEditNote, MdDeleteForever } from "react-icons/md";
import DeleteModal from './Options/DeleteModal';
import axios from '../../constents/axios';
import EditModal from './Options/EditModal';
import { GrChapterAdd } from 'react-icons/gr';
import AddModal from './Options/AddModal';

const EBooks = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [selectedEbook, setSelectedEbook] = useState(null);
    const [activeKey, setActiveKey] = useState('first');
    const [UGCNETARR, setUGCNETARR] = useState([]);
    const [CUETPGARR, setCUETPGARR] = useState([]);
    const [CUETUGARR, setCUETUGARR] = useState([]);
    const [CSIRNETARR, setCSIRNETARR] = useState([]);

    const handleShowEdit = (ebook) => {
        setSelectedEbook(ebook);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => setShowEditModal(false);

    const handleShowDelete = (ebook) => {
        setSelectedEbook(ebook);
        setShowDeleteModal(true);
    };
    const handleCloseDelete = () => setShowDeleteModal(false);

    const handleAdd = () => {
        setShowAddModal(true);
    };
    const handleCloseAdd = () => setShowAddModal(false);

    const fetchEBooks = async () => {
        try {
            const response = await axios.get('/getEbook');
            const ebooks = response.data;

            const ugcNetArr = [];
            const cuetPgArr = [];
            const cuetUgArr = [];
            const csirNetArr = [];

            ebooks.data.forEach(ebook => {
                switch (ebook.type) {
                    case 'UGC NET':
                        ugcNetArr.push(ebook);
                        break;
                    case 'CUET PG':
                        cuetPgArr.push(ebook);
                        break;
                    case 'CUET UG':
                        cuetUgArr.push(ebook);
                        break;
                    case 'CSIR':
                        csirNetArr.push(ebook);
                        break;
                    default:
                        break;
                }
            });

            setUGCNETARR(ugcNetArr);
            setCUETPGARR(cuetPgArr);
            setCUETUGARR(cuetUgArr);
            setCSIRNETARR(csirNetArr);
        } catch (error) {
            console.error('Error fetching eBooks:', error);
        }
    };

    useEffect(() => {
        fetchEBooks();
    }, []);

    return (
        <div className={styles.ebook}>
            <div className={styles.ebookHead}>
                <h1><strong>E-Books</strong></h1>
            </div>
            <div>
                <Tab.Container
                    id="left-tabs-example"
                    activeKey={activeKey}
                    onSelect={(k) => setActiveKey(k)}
                >
                    <div className={styles.addButton}>
                        <br />
                        <Button onClick={handleAdd} className="gradientBtn" variant='primary'>
                            <GrChapterAdd className='fs-5' /><strong> Add New Ebook</strong>
                        </Button>
                    </div>
                    <Nav variant="pills" className={styles.ebookButtons}>
                        <Nav.Item>
                            <Nav.Link
                                className={`${styles.navItem} ${activeKey === 'first' ? styles.activeNavItem : ''}`}
                                eventKey="first"
                            >
                                CUET UG
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className={`${styles.navItem} ${activeKey === 'second' ? styles.activeNavItem : ''}`}
                                eventKey="second"
                            >
                                CUET PG
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className={`${styles.navItem} ${activeKey === 'third' ? styles.activeNavItem : ''}`}
                                eventKey="third"
                            >
                                UGC NET
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                className={`${styles.navItem} ${activeKey === 'fourth' ? styles.activeNavItem : ''}`}
                                eventKey="fourth"
                            >
                                CSIR NET
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Table bordered hover variant="primary" className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Caption</th>
                                        <th>Banner Path</th>
                                        <th>File Path</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CUETUGARR.length > 0 ? (
                                        CUETUGARR.map((ebook, index) => (
                                            <tr key={ebook.id}>
                                                <td className={styles.tableData}>{index + 1}</td>
                                                <td className={styles.tableData}>{ebook.caption}</td>
                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}
                                                    </a>
                                                </td>

                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.file}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/{ebook.file}</a></td>
                                                <td className={styles.optionButtons}>
                                                    <Button className={styles.button} variant='warning' onClick={() => handleShowEdit(ebook)}><MdOutlineEditNote className='fs-5' /></Button>
                                                    <Button variant='danger' onClick={() => handleShowDelete(ebook)}><MdDeleteForever /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No eBooks available.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Table bordered hover variant="primary" className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Caption</th>
                                        <th>Banner Path</th>
                                        <th>File Path</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CUETPGARR.length > 0 ? (
                                        CUETPGARR.map((ebook, index) => (
                                            <tr key={ebook.id}>
                                                <td className={styles.tableData}>{index + 1}</td>
                                                <td className={styles.tableData}>{ebook.caption}</td>
                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}
                                                    </a>
                                                </td>

                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.file}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/{ebook.file}</a></td>
                                                <td className={styles.optionButtons}>
                                                    <Button className={styles.button} variant='warning' onClick={() => handleShowEdit(ebook)}><MdOutlineEditNote className='fs-5' /></Button>
                                                    <Button variant='danger' onClick={() => handleShowDelete(ebook)}><MdDeleteForever /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No eBooks available.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <Table bordered hover variant="primary" className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Caption</th>
                                        <th>Banner Path</th>
                                        <th>File Path</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {UGCNETARR.length > 0 ? (
                                        UGCNETARR.map((ebook, index) => (
                                            <tr key={ebook.id}>
                                                <td className={styles.tableData} >{index + 1}</td>
                                                <td className={styles.tableData}>{ebook.caption}</td>
                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}
                                                    </a>
                                                </td>

                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.file}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/{ebook.file}</a></td>
                                                <td className={styles.optionButtons}>
                                                    <Button className={styles.button} variant='warning' onClick={() => handleShowEdit(ebook)}><MdOutlineEditNote className='fs-5' /></Button>
                                                    <Button variant='danger' onClick={() => handleShowDelete(ebook)}><MdDeleteForever /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No eBooks available.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <Table bordered hover variant="primary" className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Caption</th>
                                        <th>Banner Path</th>
                                        <th>File Path</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {CSIRNETARR.length > 0 ? (
                                        CSIRNETARR.map((ebook, index) => (
                                            <tr key={ebook.id}>
                                                <td className={styles.tableData}>{index + 1}</td>
                                                <td className={styles.tableData}>{ebook.caption}</td>
                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.banner}
                                                    </a>
                                                </td>

                                                <td className={styles.tableData}>
                                                    <a href={`https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/${ebook.file}`} target="_blank" rel="noopener noreferrer">
                                                        https://awstrialfileuploads.s3.ap-south-1.amazonaws.com/{ebook.file}</a></td>
                                                <td className={styles.optionButtons}>
                                                    <Button className={styles.button} variant='warning' onClick={() => handleShowEdit(ebook)}><MdOutlineEditNote className='fs-5' /></Button>
                                                    <Button variant='danger' onClick={() => handleShowDelete(ebook)}><MdDeleteForever /></Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No eBooks available.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            {selectedEbook && (
                <DeleteModal show={showDeleteModal} onHide={handleCloseDelete} ebook={selectedEbook} onSuccess={fetchEBooks} />
            )}
            {selectedEbook && (
                <EditModal show={showEditModal} onHide={handleCloseEdit} ebook={selectedEbook} onSuccess={fetchEBooks} />
            )}
            <AddModal show={showAddModal} onHide={handleCloseAdd} onSuccess={fetchEBooks} />
        </div>
    );
};

export default EBooks;
