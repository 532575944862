import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import axios from '../../../../constents/axios';
import { MdArrowBack, MdOutlineAddBox } from 'react-icons/md';
import Select from 'react-select';

const EditTopics = ({ show, onHide, courseName, currentTopicName, onSuccess }) => {
  const [topicName, setTopicName] = useState(currentTopicName);
  const [error, setError] = useState(null);
  const [topics, setTopics] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false); 
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  const fetchTopics = async () => {
    try {
        const response = await axios.get(`/get-topics/${courseName}`, {
          headers: {
              'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
          }
      });
        setTopics(response.data?.data || []); 
    } catch (error) {
        console.error('Error fetching topics:', error);
    }
};

const handleAddNewTopic = async () => {
  try {
      const newTopic = topicName;
      await axios.post(`/add-newTopic`, {
          course_id: `${courseName}`,
          topics: newTopic,
          subtopic: "",
      },{
        headers: {
            'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
        }
    });
      fetchTopics();
      setIsCreatingNew(false); 
      setMenuIsOpen(true); 
      setTopicName(''); 
      onSuccess(); 
  } catch (error) {
      console.error('Error adding new topic:', error);
  }
};


  const handleEditTopic = async () => {
    if (!topicName.trim()) {
      setError('Topic name cannot be empty');
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    }
  
    try {
      const response = await axios.put(`/edit-topic`, 
        { 
          course_id: courseName, 
          currentTopic: currentTopicName, 
          newTopic: topicName
        }, {
          headers: {
              'autherisation': `V9YlI4AgRJa97h9JT45acmijLliEcCEMnxZLtL7VlnWVETZuitk2MYoj0pCcrmHa`,
          }
      });
  
      if (response.status === 200) {
        onSuccess();
        onHide(); 
      } else {
        setError('Failed to edit topic');
      }
    } catch (error) {
      console.error('Error editing topic:', error);
      setError('Error editing topic');
    }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.value === 'create-new') {
        setIsCreatingNew(true);
    } else {
        setTopicName(selectedOption.value);
        setIsCreatingNew(false);
    }
};

  const handleBackToSelect = () => {
    setIsCreatingNew(false);
    setTopicName('');
};
  
  const handleTopicNameChange = (e) => {
    e.preventDefault();
    setTopicName(e.target.value);
  };

  useEffect(() => {
    fetchTopics();
    setTopicName(currentTopicName);
  }, [currentTopicName]);

  const options = [
    { value: 'create-new', label: <><MdOutlineAddBox /> Create New Topic</> }, 
    ...(topics?.map((t) => ({ value: t.topic, label: t.topic })) || []), 
];

  return (
    <Modal show={show} size="lg" backdrop="static" onHide={onHide}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Edit Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {isCreatingNew ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <MdArrowBack
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                                size={24}
                                onClick={handleBackToSelect}
                            />
                            <Form.Control
                                type="text"
                                placeholder="Enter new topic name"
                                value={topicName}
                                onChange={(e) => setTopicName(e.target.value)}
                            />
                        </div>
                    ) : (
                        <Select
                            options={options}
                            value={{ value: topicName, label: topicName }}
                            onChange={handleSelectChange}
                            placeholder="Select a Topic"
                            onMenuOpen={() => setMenuIsOpen(true)}
                            menuIsOpen={menuIsOpen} 
                            onMenuClose={() => setMenuIsOpen(false)} 
                        />
                    )}
          {/* <Form.Group controlId="topicName">
            <Form.Label>Topic Name</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              value={topicName}
              onChange={handleTopicNameChange}
              placeholder="Edit Topic"
              required
            />
            </Form.Group> */}
            {error && <Form.Text className="text-danger">{error}</Form.Text>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={!topicName.trim()} onClick={isCreatingNew ? handleAddNewTopic : handleEditTopic}>
          {isCreatingNew ? 'Add New Topic' : 'Edit Topic'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditTopics;
