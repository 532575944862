import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import styles from "../../../styles/addTest.module.css";

const AddTest = () => {
  const testTypeOptions = [
    { value: "mt", label: "Mock Test" },
    { value: "dt", label: "Daily Test" },
    { value: "cpt", label: "Categorised Practise Test" },
    { value: "cpyq", label: "Categorised PYQ" },
    { value: "pyq", label: "Previous Year Questions" },
    { value: "mmt", label: "Mega Mock Test" },
    { value: "q", label: "Quiz" },
  ];
  
  const labelOptions = [
    { value: "label1", label: "Label 1" },
    { value: "label2", label: "Label 2" },
    { value: "label3", label: "Label 3" },
  ];
  const sectionOptions = [
    { value: "section1", label: "Section 1" },
    { value: "section2", label: "Section 2" },
    { value: "section3", label: "Section 3" },
  ];
  const batchOptions = [
    { value: "batch1", label: "Batch 1" },
    { value: "batch2", label: "Batch 2" },
    { value: "batch3", label: "Batch 3" },
  ];
  const paperOptions = [
    { value: "paper1", label: "Paper 1" },
    { value: "paper2", label: "Paper 2" },
    { value: "paper3", label: "Paper 3" },
  ];
  const instructionOptions = [
    { value: "instruction1", label: "Instruction 1" },
    { value: "instruction2", label: "Instruction 2" },
    { value: "instruction3", label: "Instruction 3" },
  ];

  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    duration: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    testType: null,
    label: null,
    sectionId: null,
    batchId: null,
    paper: null,
  });

  const [isSectionEnabled, setIsSectionEnabled] = useState(false);
  const [isDefaultSettings, setIsDefaultSettings] = useState(false);
  const [isMoreDetails, setIsMoreDetails] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isOffer, setIsOfferPaid] = useState(true);
  const [isPublishResult, setIsPublishResult] = useState(true);
  const [isPublishDemo, setIsPublishDemo] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption, field) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption }));
  };

  const handleToggleSection = () => {
    setIsSectionEnabled(!isSectionEnabled);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    // Add your submission logic here (e.g., API call)
  };

  useEffect(() => {
    
  }, );

  return (
    <div className={styles.container}>
      <h3>Add Test</h3>
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            {/* Enable Section Toggle */}
            <div className={styles.enableBarDiv}>
              <p>Enable Section for this Test:</p>
              <label className={styles.toggleSwitch}>
                <input
                  type="checkbox"
                  checked={isSectionEnabled}
                  onChange={handleToggleSection}
                />
                <span className={styles.slider}></span>
              </label>
            </div>

            {/* Section ID */}
            {isSectionEnabled && (
              <Form.Group>
                <Form.Label>Section ID:</Form.Label>
                <Select
                  options={sectionOptions}
                  onChange={(sectionOptions) =>
                    handleSelectChange(sectionOptions, "sectionId")
                  }
                  placeholder="Search Section ID..."
                  isSearchable
                />
              </Form.Group>
            )}

            {/* Title */}
            <Form.Group>
              <Form.Label>Title:</Form.Label>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter title of the test"
                value={formData.title}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Test Type */}
            <Form.Group>
              <Form.Label>Test Type:</Form.Label>
              <Select
                options={testTypeOptions}
                onChange={(testTypeOptions) =>
                  handleSelectChange(testTypeOptions, "testType")
                }
                placeholder="Select Test Type"
                isSearchable
              />
            </Form.Group>

            {/* Batch ID */}
            {!isSectionEnabled && (
              <Form.Group>
                <Form.Label>Batch ID:</Form.Label>
                <Select
                  options={batchOptions}
                  onChange={(batchOptions) =>
                    handleSelectChange(batchOptions, "batchId")
                  }
                  placeholder="Search Batch ID..."
                  isSearchable
                />
              </Form.Group>
            )}

            {/* Topic */}
            <Form.Group>
              <Form.Label>Topic:</Form.Label>
              <Form.Control
                type="text"
                name="topic"
                placeholder="Enter topic"
                value={formData.topic}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Paper */}
            <Form.Group>
              <Form.Label>Paper (Categorised Practice Test only):</Form.Label>
              <Select
                options={paperOptions}
                onChange={(paperOptions) =>
                  handleSelectChange(paperOptions, "paper")
                }
                placeholder="Search Paper..."
                isSearchable
              />
            </Form.Group>

            {/* Duration */}
            <Form.Group>
              <Form.Label>Duration:</Form.Label>
              <Form.Control
                type="text"
                name="duration"
                placeholder="Enter test duration"
                value={formData.duration}
                onChange={handleInputChange}
              />
            </Form.Group>

            {/* Start and End Date/Time */}
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>Start Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label>End Date:</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Time:</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
            </div>

            {/* Label */}
            <Form.Group>
              <Form.Label>Label:</Form.Label>
              <Select
                options={labelOptions}
                onChange={(labelOptions) =>
                  handleSelectChange(labelOptions, "label")
                }
                placeholder="Search Label..."
                isSearchable
              />
            </Form.Group>

            <Button type="submit" className="mt-3">
              Submit
            </Button>
          </div>

          {/* Right Column */}
          <div className="col-md-6">
            {/* Default Settings Toggle */}
            <div className={styles.enableBarDiv}>
              <span>Do you want to change default settings? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="defaultSettings"
                // defaultChecked
                onChange={() =>
                  setIsDefaultSettings(!isDefaultSettings)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="defaultSettings"
                defaultChecked
                onChange={() =>
                  setIsDefaultSettings(false)
                }
              />
            </div>

            {/* Default Settings Section */}
            {isDefaultSettings && (
              <>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Active:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="Active"
                    defaultChecked
                    onChange={() =>
                      setIsActive(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="Active"
                    // defaultChecked
                    onChange={() =>
                      setIsActive(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Offer As Paid:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Paid"
                    name="offerPaid"
                    defaultChecked
                    onChange={() =>
                      setIsOfferPaid(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="Free"
                    name="offerPaid"
                    // defaultChecked
                    onChange={() =>
                      setIsOfferPaid(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish the result: </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishResult"
                    defaultChecked
                    onChange={() =>
                      setIsPublishResult(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishResult"
                    // defaultChecked
                    onChange={() =>
                      setIsPublishResult(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3 d-flex gap-3">
                  <Form.Label>Do you want to publish as demo:</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="publishDemo"
                    // defaultChecked
                    onChange={() =>
                      setIsPublishDemo(true)
                    }
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="publishDemo"
                    defaultChecked
                    onChange={() =>
                      setIsPublishDemo(false)
                    }
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>General Instruction:</Form.Label>
                  <Select
                    options={instructionOptions}
                    onChange={(instructionOptions) =>
                      handleSelectChange(instructionOptions, "label")
                    }
                    placeholder="Choose Instrunctions..."
                    isSearchable
                  />
                </Form.Group>
              </>
            )}

            <br />
            {/* More Details Toggle */}
            <div className={styles.enableBarDiv}>
              <span>Do you want to add more details? </span>
              <Form.Check
                type="radio"
                label="Yes"
                name="moreDetails"
                onChange={() =>
                  setIsMoreDetails(!isMoreDetails)
                }
              />
              <Form.Check
                type="radio"
                label="No"
                name="moreDetails"
                defaultChecked
                onChange={() =>
                  setIsMoreDetails(false)
                }
              />
            </div>

            {/* More Details Section */}
            {isMoreDetails && (
              <>
                <Form.Group className="mt-3">
                  <Form.Label>Positive Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Positive Mark"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Negative Mark:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Negative Mark"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Question Attend Limit:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Limit of Questions to Attend"
                  />
                </Form.Group>
              </>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddTest;
