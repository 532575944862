import AWS from 'aws-sdk';
import axios from '../../../constents/axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DeleteModal = ({ show, onHide, ebook, onSuccess }) => {
    const [id, setId] = useState('');
    const [bannerPath, setBannerPath] = useState('');
    const [filePath, setFilePath] = useState('');

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
    });

    const s3 = new AWS.S3();

    const deleteFromS3 = (key) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
                Key: key,
            };

            s3.deleteObject(params, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                    console.log('File deleted from S3');
                }
            });
        });
    };

    const handleDelete = async () => {
        try {
            if (bannerPath) {
                await deleteFromS3(bannerPath);
            }
            if (filePath) {
                await deleteFromS3(filePath);
            }
            const response = await axios.delete(`/deleteEbook/${id}`);
            if (response.status === 200) {
                onSuccess(); 
            } else {
                console.error('Failed to delete eBook:', response.status);
            }
        } catch (error) {
            console.error('Error deleting eBook:', error);
        } finally {
            onHide(); 
        }
    };

    useEffect(() => {
        if (ebook) {
            setId(ebook.id);
            setBannerPath(ebook.banner); 
            setFilePath(ebook.file); 
        }
    }, [ebook]);

    return (
        <div>
            <Modal show={show} backdrop="static" onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">Delete E-book File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <span>Are you sure you want to delete this file?</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>Cancel</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteModal;
