import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import axios from '../../constents/axios';

const EditReel = ({ show, handleClose, reel, onSuccess }) => {
  const [formState, setFormState] = useState({
    topic: '',
    description: '',
    isFreeReel: false,
    selectedSubject: '',
  });

  const [subjects, setSubjects] = useState([]);

  // Sync formState with reel prop
  useEffect(() => {
    if (reel) {
      setFormState({
        topic: reel.topic || '',
        description: reel.description || '',
        isFreeReel: reel.isFree || false,
        selectedSubject: reel.subject || '',
      });
    }
  }, [reel]);

  // Fetch subjects based on isFreeReel
  useEffect(() => {
    const fetchSubjects = async () => {
      if (formState.isFreeReel !== undefined) {
        try {
          const endpoint = formState.isFreeReel ? '/getFreeSubjects' : '/getPremiumSubjects';
          const { data } = await axios.get(endpoint);
          setSubjects(data);
        } catch (err) {
          console.error('Error fetching subjects:', err);
          setSubjects([]);
        }
      }
    };

    fetchSubjects();
  }, [formState.isFreeReel]);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Save changes
  const handleSave = async () => {
    try {
      await axios.put(`/editReel/${reel._id}`, {
        topic: formState.topic,
        description: formState.description,
        isFree: formState.isFreeReel,
        subject: formState.selectedSubject,
      });
      onSuccess(); // Call the success callback
      handleClose(); // Close the modal
    } catch (error) {
      console.error('Error updating reel:', error);
    }
  };

  if (!reel) {
    return null; // Ensure nothing is rendered if reel is not defined
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Reel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <a href={reel.reel} target="_blank" rel="noopener noreferrer">
            Click to view reel
          </a>
          <Form.Group className="mt-3">
            <Form.Label>Topic</Form.Label>
            <Form.Control
              type="text"
              name="topic"
              value={formState.topic}
              onChange={handleInputChange}
              placeholder="Enter topic"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={formState.description}
              onChange={handleInputChange}
              placeholder="Enter description"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              type="checkbox"
              name="isFreeReel"
              label="Free Reel"
              checked={formState.isFreeReel}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Choose Subject</Form.Label>
            <Form.Control
              as="select"
              name="selectedSubject"
              value={formState.selectedSubject}
              onChange={handleInputChange}
            >
              <option value="">Select Subject</option>
              {subjects?.map((subject, index) => (
                <option key={index} value={subject.subject}>
                  {subject.subject}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Save Changes
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditReel;
